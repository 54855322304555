import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

/**
 * Can be used as a rooter guard to prove whether the access is allowed to any rout,
 * or restricted only to finished offers
 */
@Injectable()
export class GtcGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromRoot.getUserState).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        // Enforced auction viewer is only allowed to see finished offers
        if ((AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonGtc(userState.userInfo) ||
            (userState.isViewerEnforcedAuction && !userState.hasAnyBuyerRoleOrAdmin)) &&
            !state.url.startsWith('/offer/')) {
          this.router.navigate(['/finished-offers']);
          return false;
        }
        return true;
      }));
  }


}
